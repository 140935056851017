@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
}

.wizard-bg {
  @apply bg-[url('../assets/background.svg')] bg-origin-padding bg-no-repeat bg-[right_2rem] bg-[length:615px_630px]
}

.welcome-bg {
  @apply bg-[url('../assets/welcome.svg')] bg-origin-padding bg-no-repeat bg-[right_10rem] bg-[length:715px_730px]
}

@layer components {
  button.btn-primary-next {
    @apply bg-primary-next-500 text-black;
    @apply hover:bg-primary-next-800 hover:text-white;
    @apply active:bg-primary-next-900 active:text-white;
  }
}


/*ASAP widget open close */
button.zd-launcher-close__app.data-theme-asap-launcher-custom.zd_asap_launcher_close{
	@apply !hidden
}
button.zd-launcher-open.zd_asap_launcher_active.data-theme-asap-launcher-custom {
	@apply !inline-flex
}